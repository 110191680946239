@import 'styles/variables';
@import 'styles/utils';

body {
  background: #f8f7f5;
  margin: 30px;
}

.bodyWrapper {
  clear: both;
}

.wrapper {
  display: flex;
  align-items: center;
  line-height: 28px;
  justify-content: center;
  flex-direction: column;
}

.logoWrapper {
  text-align: center;

  margin-top: 60px;
  margin-bottom: 30px;

  img {
    width: 150px;
  }
}

pre {
  overflow-x: scroll;
  max-width: 100%;
}

.exam {
  ul {
    li {
      margin-top: 20px;
    }
  }
  .use_case {
    margin: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px dashed $primaryColor;
  }
  .exam-img,
  video {
    width: 100%;
    border: 1px solid $subtleColor;
  }

  blockquote {
    display: flex;
    align-items: center;
    border: 1px solid $bgColor;
    background-color: $bgColor;
    margin: 15px;
    padding-left: 15px;
    border-radius: 5px;
    i {
      font-family: 'Courier New', Courier, monospace;
      padding-left: 3px;
      padding-right: 3px;
    }
  }

  &--part {
    padding-top: 40px;
    margin-top: 50px;
  }
  h5 {
    font-size: 16px;
    color: $primaryColor;
    margin-top: 50px;
    margin-bottom: 50px;
    text-decoration: underline;
  }
  h4 {
    margin-bottom: 0px;
    border-bottom: 1px solid $subtleColor;
  }

  p {
    margin-bottom: 30px;
  }

  h4 {
    margin-top: 40px;
  }

  .icon {
    position: relative;
    top: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.block {
  display: flex;
  align-items: center;
  border: 1px solid $subtleColor;
  background-color: $bgColor;
  margin: 15px;
  padding-right: 30px;
  border-radius: 5px;
  .icon-svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
    margin-left: 20px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &--node {
    .icon-svg {
      background-image: url(https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg);
    }
  }
  &--info {
    .icon-svg {
      background-image: url(https://cdnjs.cloudflare.com/ajax/libs/octicons/8.5.0/svg/info.svg);
    }
  }
}

.primarySiloButton {
  line-height: 1.5;
  border-radius: 0.25em;
  padding: 0.5em;
  color: $primaryColor;
  background-color: transparent;
  border: 1px solid $primaryColor;
  transition: opacity 0.2s ease;
  border-radius: 3px;
  outline: 0;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    border: 1px solid $primaryDarkColor;
  }

  &--full {
    text-align: center;
    width: 100%;
  }

  &.disabled {
    pointer-events: none;
    color: $subtleColor;
    border-color: $subtleColor;
  }

  &--noborder {
    //color: $borderColor;
    border: none;
  }
}

.primaryInput {
  line-height: 1.5;
  border-radius: 0.25em;
  padding: 0.5em;
  border: 1px solid $subtleColor;
  border-radius: 3px;
  outline: 0;
  margin-right: 10px;
  min-width: 250px;
  font-size: 12px;

  &.valid {
    border-color: $primaryColor;
  }
}

div[class*='-control'] {
  border-color: $sideBarBorderColor;
  box-shadow: 0 0 0 0px $primaryColor;
}

div[class*='-control']:hover {
  border-color: $primaryColor;
  box-shadow: 0 0 0 1px $primaryColor;
}

div[class*='-control']:hover {
  border-color: $primaryColor;
  box-shadow: 0 0 0 1px $primaryColor;
}

div[class*='-option']:hover {
  background-color: $primaryColor;
}

// div[class*='-MenuList'] div[class*='-option'] {
//   background-color: $sideBarBorderColor;
// }
